<template>
          <div class="conents">
              <div class="top">
                  <!-- 活力开发区 -->
              <p class="toptitle"> {{ $t("language.DynamicDevelopmentZone") }}</p>
              <!--  1984年10月经国务院批准设立，1985年3月开工建设，是全国首批14个国家级开发区之一、中国（山东）自由贸易试验区烟台片区承载地，是烟 台国际招商产业园、中韩产业园、中日产业园和综保区主阵地，山东新旧动能转换核心区。 先后荣获ISO14000国家示范区、中国工业园区环境管理示范区、全国循环经济试点园区、国家新型工业化示范基地、国家知识产权试点 园区、全国模范劳动关系和谐工业园区、联合国绿色工业园区等称号。2020年地区生产总值增长5.5%，固定 资产投资增长7%，进出口总额增长14.9%，实际使用外资增长16.6%，一般公共预算收入 增长5.3%，主要经济指标均高于省市平均水平。 -->
              <p class="topcontent">
                {{ $t("language.Approvedbythe") }}
              </p>
          </div>
          <div class="center">
              <!-- <p class="centerbac">城岸云浪 海上银贝</p> -->
              <!-- 精彩八角湾 -->
              <p class="centertit"> {{ $t("language.WonderfuloctagonBay") }}</p>
              <!--   八角湾，一片孕育着希望的热土，“创新”是它的一个“标签”，“文旅会展”也是这片区域的鲜明特色。 位于烟台开发区中心位置的八角湾中央创新区，主要打造的是中央创新区。重点布局中央商务区、科技创新区、会展文旅区“三大板块”。八角湾部分区域亦被划入山东自贸试验区烟台片区和中韩(烟台)产业园范围内。 作为烟台市重点规划建设的九大片区之一，八角湾位于烟台开发区西侧，已成为最具有发展潜力的黄金湾区之一，这一点，从其周边产业布局即可见一斑。八角湾周边聚集了万华、富士康、通用汽车等一批优质产业龙头项目。紧临“三港一站”，距蓬莱国际机场10公里、环渤海高铁烟台西站5.8公里，荣乌高速、206国道穿境而过，北邻烟台港西港区，集区位、自然资源、经济腹地万千优势于一身。 -->
              <p class="centert">
                {{ $t("language.BajiaoBayis") }}
                {{ $t("language.BajiaowanCentral") }}
                {{ $t("language.Asoneofthenine") }}
              </p>
              <img class="bigimg" src="../../assets/image/八角湾中央创新区.png" alt="">
              <p class="imgbox">
                  <img class="smimg" src="../../assets/image/微信图片_20200113153936.png" alt="">
                  <img class="smimg" src="../../assets/image/会展中心-2020218-13.png" alt="">
              </p>
          </div>
          </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .top{
        width: 100%;
        padding: 0.08rem;
        box-sizing: border-box;
    }
    .center{
        width: 100%;
        padding: 0.08rem;
        box-sizing: border-box;
    }
    .toptitle{
        text-align: center;
        font-size: 0.2rem;
        margin-bottom: 0.1rem;
        font-weight: 800;
    }
    .topcontent{
        font-size: 0.14rem;
        line-height: 0.2rem;
    }
    .centerbac{
        color: #197cc3;
        font-size: 0.2rem;
        text-align: center;
        margin-bottom: 0.23rem;
    }
    .centertit{
        font-size: 0.2rem;
        text-align: center;
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    .centert{
        font-size: 0.14rem;
        line-height: 0.2rem;
    }
    .bigimg{
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 0.08rem;
    }
    .imgbox{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .smimg{
        width: calc(50% - 0.04rem);
        height: auto;
        display: block;
    }
</style>