<template>

          <div class="contents">
              <!-- 鲜美烟台 -->
              <h5>{{$t('language.DeliciousYantai')}}</h5>
              <!-- 烟台名称源于烟台山,是国务院批复确定的中国山东半岛的中心城市,环海地区重要的港口城市
                ,是国家历史文化名城2020年,烟台市继续保留全国文明城市荣誉称号,成为全国唯一的“六连
                冠地城市
                烟台地处山东半岛中部,东连威海,西接潍坊,西南与青岛邻,北渤海黄海,与宁半岛对
                ,并与大连海相望,共同形成拱卫首都北京的海上门户
                烟台是环海济圈胶东经济圈内重要节点城市中国首批14个沿海开放城市之中国海城
                市,“一带一路”国家战略重点建设港口城市
                烟台山青水秀,气候人,有丰富的自然景现和人文景现尤其是夏季,海风抚面,清爽可人:山
                花烂漫,人心;海光山色,交相辉晚,既是避纳凉之胜地,也是各地游客旅游观光的理想填
                地,市内旅游区众多,有国家5A级旅游景区3处、4A级19处、3A级50处,获“中国优秀城
                市”称号 -->
              <p>
                {{$t('language.introduced')}}
              </p>
              <img class="contimg" src="../../assets/image/inright.png" alt="">
              <div class="botnav">
                  <div>
                      <p>
                          <!-- VIO国际葡萄酒城 -->
                          <span> {{$t('language.InternationalWineCity')}}</span>
                          <!-- 联合国人居奖 -->
                          <span> {{$t('language.HabitatAward')}}</span>
                      </p>
                      <p>
                          <!-- 最佳中国魅力城市 -->
                          <span> {{$t('language.CityinChina')}}</span>
                          <!-- 中国最美丽城市 -->
                          <span>{{$t('language.beautifulCITY')}}</span>
                      </p>
                      <p>
                          <!-- 国家园林城市 -->
                          <span>{{$t('language.GardenCity')}}</span>
                          <!-- 避暑旅游城市 -->
                          <span>{{$t('language.resortcity')}}</span>
                      </p>
                  </div>
                  <div>
                      <p>
                          <!-- 中国投资环境金牌城市 -->
                          <span>{{$t('language.investmentenvironment')}}</span>
                          <!-- 中国十大文明城市 -->
                          <span> {{$t('language.citiesinChina')}}</span>
                      </p>
                      <p>
                          <!-- 国家卫生城市 -->
                          <span>{{$t('language.HealthCity')}}</span>
                          <!-- 国家森林城市 -->
                          <span>{{$t('language.GardenCity')}}</span>
                      </p>
                      <p>
                          <!-- 中国最具生态竞争力城市 -->
                          <span>{{$t('language.competitivecity')}}</span>
                          <!-- 国家森林城市 -->
                          <span>{{$t('language.ForestCity')}}</span>
                      </p>
                  </div>
              </div>
              <img class="botimg" src="../../assets/image/inbottom.png" alt="">
          </div>
</template>

<script>
export default {
}
</script>

<style scoped>
  .content{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 0.01rem solid #999;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .contimg{
        width: 100%;
        height: auto;
        display: block;
    }
    .contents{
        width: 100%;
        margin-top: 0.2rem;
    }
    .contents p{
        padding: 0 0.08rem;
        line-height: 0.2rem;
        font-size: 0.14rem;
        margin-bottom: 0.1rem;
    }
    .contents h5{
        padding: 0 0.08rem;
        font-size: 0.20rem;
        margin-bottom: 0.08rem;
    }
    .botimg{
        width: 100%;
        height: auto;
        display: block;
    }
    .botnav p{
        width: calc(100% - 0.24rem);
        display: flex;
        justify-content: space-between;
    }
    .botnav p span{
        width: 50%;
        display: block;
    }
    .botnav p span:nth-of-type(2){
        padding-left: 0.35rem;
        box-sizing: border-box;
    }
    .botnav{
        width: 100%;
        margin-top: 0.08rem;
        padding: 0 0.12rem;
        box-sizing: border-box;
    }
</style>