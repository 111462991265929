<template>
    <div class="main">
        <div class="title">
            <!-- 联系我们 -->
            <h1>{{$t('language.ContactUs')}}</h1>
            <!-- <h1>contact us</h1> -->
        </div>
        <div class="main_img">
            <div class="img_one down">
                <div class="img_bor">
                    <img src="../../assets/image/weizhi.png" alt="">
                </div>
                <div class="describe">
                    <!-- 地址 -->
                    <p>{{$t('language.Site')}}</p>
                    <!-- 山东省烟台经济技术开发区 -->
                    <p>{{$t('language.YantaiEconomic')}} </p>
                    <!-- 北京中路31号 -->
                    <p>{{$t('language.MiddleRoad')}}</p>
                </div>
            </div>
            <div class="down">
                <div class="img_bor">
                    <img src="../../assets/image/电话.png" alt="">
                </div>
                <div class="describe">
                    <!-- 电话 -->
                    <p>{{$t('language.Phone')}}</p>
                    <p>0535-6959157</p>
                </div>
            </div>
            <div class="down">
                <div class="img_bor">
                    <img src="../../assets/image/邮箱.png" alt="">
                </div>
                <div class="describe">
                    <!-- 邮箱 -->
                    <p>{{$t('language.Mailbox')}}</p>
                    <p>ytbjw@ybicec.com</p>
                    <p></p>
                </div>
            </div>
            <div>
                <div class="qr_code">
                    <img src="../../assets/image/201628491340_.pic_hd.png" alt="">
                </div>
                <div class="describe last">
                    <!-- 微信二维码 -->
                    <p>{{$t('language.WechatQRcode')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main{
    width: 100%;
    text-align: center;
}
.title{
    margin: 0.2rem 0;
}
.main_img{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2rem;
}
.down{
    margin-bottom: 0.2rem;
}
</style>