<template>
    <div class="content">
          <div class="conttop">
              <!-- 公司介绍 -->
              <h6>  {{ $t("language.CompanyIntroduction") }}</h6>
              <p>
                  <img src="../../assets/image/业达城市发展集团LOGO（绿）.png" alt="">
                  <!--   烟台业达城市发展集团有限公司是烟台经济技术开发区区属重点国有企业，组建于2019年9月，注册资本30亿元。截至2020年末，资产总额约265亿元，净资产159亿元，现有员工400余人。是一家集城市更新建设、城市综合运营服务、产业培育和金融服务、会展文旅和海洋经济四大主业为核心的国有资本投资运营平台。 -->
                  <span>
                    {{ $t("language.YantaiYedaUrban") }}
                  </span>
              </p>
                <img class="img" src="../../assets/image/城发1.png" alt="">
          </div>
          <div class="contcenter">
              <!-- 烟台业达文旅有限公司 -->
              <h6>{{ $t("language.YantaiYeDaWen") }}</h6>
              <!-- 烟台业达文旅有限公司成立于2019年，注册资本5亿元，是区属国有企业烟台业达城市发展集团有限公司旗下全资子公司。业达文旅集团秉承“让生活更美好”的品牌理念，以提升现代化滨海新城品质为宗旨，繁荣服务业态、拓展海洋经济，深挖文化旅游、留住城市底蕴，致力打造烟台最具吸引力的城市板块，开拓会展文旅新篇章，为建设现代化、靓丽文明的滨海新城助力！ -->
              <span>{{ $t("language.Foundedin2019with") }}</span>
              <img src="../../assets/image/海岸线 (1)@2x.png" alt="">
          </div>
          <div class="contcenter">
              <!-- 烟台业达国际会展有限公司 -->
              <h6>{{ $t("language.YantaiYedaInternational") }}</h6>
              <!-- 烟台业达国际会展有限公司成立于2020年8月，注册资本500万元，是区属国有企业烟台业达城市发展集团有限公司旗下三级全资子公司。 业达国际会展有限公司是业达城发集团履行城市运营服务功能的核心企业之一，是烟台八角湾国际会展中心场馆运营商。公司下设营销部、营运部、会议餐饮部、工程物业部、人事行政部及财务部，致力于用国际化的经营理念、先进的管理体系、至臻的服务品质，为参会人员提供集展览、会议、餐饮、休闲、观光购物于一体的极致服务体验。 -->
              <span>{{ $t("language.YantaiYedaInternationalExhibitionCo") }}</span>
              <img src="../../assets/image/文旅集团未来办公场地.png" alt="">
          </div>
      </div>
</template>

<script>
export default {
//   data() {
//         return {
//             nav:[
//                  {title:"鲜美烟台",background: "#fff",color: "#000"},
//                 {title:"活力开发区",background: "#fff",color: "#000"},
//                 {title:"公司介绍",background: "#197cc3",color: "#fff"},
//                 {title:"联系我们",background: "#fff",color: "#000"},
//             ],     
//             activeNames: ['0'], 
//             navtitle:"公司介绍" 
//         }
//     },
//     // created() {
//     //     this.navtitle = this.$route.query.title
//     // },
//     methods:{
//         skip(val){
//             if(val=="鲜美烟台"){
//                 this.$router.push("/deliciousyt")
//             }
//             if(val=="活力开发区"){
//                 this.$router.push("/development")
//             }
//             if(val=="联系我们"){
//                 this.$router.push("/contact")
//             }
//         }
//     }
}
</script>

<style scoped>
  .conttop p{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
        font-size: 0.14rem;
        line-height: 0.2rem;
    }
    .conttop p img{
        width: 1.07rem;
        display: block;
        height: 1.07rem;
        margin: 0 auto 0.08rem;
    }
    .conttop p span{
        display: block;
        width: 100%;
        font-size: 0.14rem;
        text-indent: 2em;
    }
    /* .navs{
        width: 100%;
        height: auto;
    } */
    .img{
        display: block;
        width: calc(100% - 0.16rem);
        margin: 0.08rem auto 0.08rem;
        height: auto;
    }
    /* .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 0.01rem solid #999;
        font-size: 0.16rem;
        height: 0.54rem;
    } */
    /* .navs >>> .van-cell__title{
        flex: none;
    } */
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .conttop h6{
        width: 100%;
        text-align: center;
        font-size: 0.2rem;
        margin: 0.10rem 0;
    }
    .contcenter h6{
        background: url(../../assets/image/摄图网_401052815_创意中国风红色花纹边框（非企业商用）@2x.png) no-repeat 0.45rem;
        width: 100%;
        line-height: 0.88rem;
        text-align: center;
        font-size: 0.2rem;
        margin: 0.10rem 0;
    }
    .contcenter span{
        display: block;
        width: 100%;
        font-size: 0.14rem;
        text-indent: 2em;
        margin-bottom: 0.08rem;
    }
    .contcenter img{
        display: block;
        width: 100%;
        height: auto;
    }
    .contcenter:nth-last-of-type(1) img{
        margin-bottom: 0.08rem;
    }
    .contcenter{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
    }
</style>