<template>
  <div class="del">
      <!-- 鲜美烟台 -->
      <div class="nav">
          <H5Carousel/>
      </div>
      <div class="content">
          <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" @click="gopage(index)" :key="index" :style="{background:item.background,color:item.color}">
                       {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
         
      </div>
        <div class="content" v-if="navtitle == '鲜美烟台'|| navtitle == 'Delicious Yantai'">
            <Deliciousyt :tablelists="tablelist"/>
            </div>
            <div class="content" v-else-if="navtitle == '活力开发区'|| navtitle == 'Dynamic development zone'">
            <Development />
            </div>
            <div class="content" v-else-if="navtitle == '公司介绍'|| navtitle == 'Company introduction'">
            <Introduce />
            </div>
            <div class="content" v-else-if="navtitle == '联系我们'|| navtitle == 'Contact us'">
            <Contact />
        </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
import Deliciousyt from '../../components/h5ourselves/deliciousyt.vue'
import Development from '../../components/h5ourselves/development.vue'
import Introduce from '../../components/h5ourselves/introduce.vue'
import Contact from '../../components/h5ourselves/contact.vue'
export default {
    components:{
        H5Carousel,H5Bottom,Deliciousyt,Development,Introduce,Contact
    },
  data() {
        return {
            nav:[
                 {title:"鲜美烟台",background: "#fff",color: "#000",titles:"Delicious Yantai"},
                {title:"活力开发区",background: "#fff",color: "#000",titles:"Dynamic development zone"},
                {title:"公司介绍",background: "#fff",color: "#000",titles:"Company introduction"},
                {title:"联系我们",background: "#197cc3",color: "#fff",titles:"Contact us"},
            ],
            activeNames: ['0'],
            navtitle:"",
            tablelist:[] ,
            show:""
        }
    },
    // created() {
    //     this.navtitle = this.$route.query.title
    // },
    // methods:{
    //     skip(val){
    //         if(val=="活力开发区"){
    //             this.$router.push("/development")
    //             console.log(111);
    //         }
    //         if(val=="公司介绍"){
    //             this.$router.push("/introduce")
    //         }
    //         if(val=="鲜美烟台"){
    //             this.$router.push("/deliciousyt")
    //         }
    //     }
    // },
     created() {
        if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.navtitle = '鲜美烟台'
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff' 
                        if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    return item
                })
                 if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }
    },
    methods:{
 
        gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Delicious Yantai'
                }else{
                    this.navtitle = '鲜美烟台'
                }
               this.activeNames = ["0"]
               this.$router.replace({path:'/deliciousyt',query:{path:`鲜美烟台`}})
           }else if(val == 1){
              if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Dynamic development zone'
                }else{
                    this.navtitle = '活力开发区'
                }
               this.activeNames = ["1"]
               this.$router.replace({path:'/deliciousyt',query:{path:`活力开发区`}})
           }else if(val == 2){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Company introduction'
                }else{
                    this.navtitle = '公司介绍'
                }
               this.activeNames = ["2"]
               this.$router.replace({path:'/deliciousyt',query:{path:`公司介绍`}})
           }else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Contact us'
                }else{
                    this.navtitle = '联系我们'
                }
               this.activeNames = ["3"]
               this.$router.replace({path:'/deliciousyt',query:{path:`联系我们`}})
           }
        }
    }
}
</script>

<style scoped>
    .del{
        width: 100%;
        height: 100%;
    }
   .navs{
        width: 100%;
        height: auto;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 0.01rem solid #999;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
        .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
</style>